'use client';
import React, { PropsWithChildren, createContext, useCallback, useContext } from 'react';
import { driver } from 'driver.js';
import './onboarding.css';
import 'driver.js/dist/driver.css';
import { OnboardingFlows } from './constants';
import { useMarkOnboarding } from './useUserOnboardingMutation';
import { useAvailableFlows } from './useAvailableFlows';
import { useGrowthbookContext } from '../GrowthbookContext';
import { useSessionStorage } from '@mantine/hooks';

type RunFlowMetaType = {
  id?: number;
};

type OnboardingContextType = {
  runFlow: (name: OnboardingFlows, meta?: RunFlowMetaType) => void;
};

const OnboardingContext = createContext<OnboardingContextType>({
  runFlow: () => {},
});

export const OnboardingProvider = ({ children }: PropsWithChildren) => {
  const growthbook = useGrowthbookContext();
  const [_, setFormOptionsOpened] = useSessionStorage({
    key: 'form-options-opened',
    defaultValue: false,
  });
  const { mutateAsync: markOnboardingComplete } = useMarkOnboarding();
  const { availableFlows } = useAvailableFlows();

  const runFlow = useCallback(
    async (name: OnboardingFlows, meta?: RunFlowMetaType) => {
      if (!availableFlows.includes(name)) {
        return;
      }
      if (!growthbook?.isOn('onboarding')) {
        return;
      }
      const flow = driver({ popoverClass: 'custom-theme' });
      switch (name) {
        case OnboardingFlows.ASSET_MORE_INFO:
          const selector = `#asset-${meta?.id}`;
          const isAssetReady = document.querySelector(selector);
          if (!isAssetReady) {
            setTimeout(() => {
              runFlow(OnboardingFlows.ASSET_MORE_INFO, meta);
            }, 50);
            return;
          }

          flow.highlight({
            element: selector,
            popover: {
              title: 'Expand asset',
              description: 'Click on this row to see details of your model',
              side: 'top',
              align: 'center',
            },
          });
          break;
        case OnboardingFlows.SIDEBAR:
          await new Promise((resolve) => setTimeout(resolve, 100));
          flow.highlight({
            element: `#${OnboardingFlows.SIDEBAR}`,
            popover: {
              title: 'Sidebar',
              description: 'Sidebar is where you will find your model related information. You can also resize it!',
              showButtons: ['next'],
              nextBtnText: 'Okay',
              side: 'left',
              align: 'center',
              onNextClick: () => {
                flow.destroy();
                setTimeout(() => {
                  runFlow(OnboardingFlows.RUN_PRESET);
                }, 5000);
              },
            },
          });
          break;
        case OnboardingFlows.RUN_PRESET:
          const presetFlow = driver({
            popoverClass: 'custom-theme',
            steps: [
              {
                element: `#${OnboardingFlows.RUN_PRESET}-0`,
                popover: { title: 'Process your model', description: 'Select the models you want to process...' },
              },
              {
                element: `#${OnboardingFlows.RUN_PRESET}-1`,
                popover: { title: 'Process your model', description: '... and then click Run 3D Processor.' },
              },
            ],
          });
          presetFlow.drive();
          break;
        case OnboardingFlows.PRESET_MODAL:
          const modalFlow = driver({
            popoverClass: 'custom-theme',
            showButtons: ['next'],
            steps: [
              {
                element: `#${OnboardingFlows.PRESET_MODAL}-0`,
                popover: {
                  title: 'Process your model',
                  description:
                    'The presets are organized in tabs. Your custom presets will be stored in My Presets and they will appear at the top of All Presets.',
                },
              },
              {
                element: `#${OnboardingFlows.PRESET_MODAL}-1`,
                popover: {
                  description:
                    'Feel free to click Run 3D Processor for any preset you want to test. You can close the modal while the 3D Processor is running.',
                },
              },
            ],
          });
          modalFlow.drive();
          break;
        case OnboardingFlows.EXPAND_ASSET:
          await new Promise((resolve) => setTimeout(resolve, 100));

          const isRunWorkflowModalOpen = document.getElementById('run-workflow-modal-body');
          if (isRunWorkflowModalOpen) {
            setTimeout(() => {
              runFlow(OnboardingFlows.EXPAND_ASSET, meta);
            }, 1000);
            return;
          }

          flow.setSteps([
            {
              element: `#asset-${meta?.id}`,
              popover: {
                description: 'Congratulations! Your output model is ready.',
                showButtons: ['next'],
                nextBtnText: 'Awesome!',
              },
            },
            {
              element: `#asset-${meta?.id}-formats`,
              popover: {
                description: 'You can download individual formats here',
                showButtons: ['next'],
                nextBtnText: 'Neat!',
              },
            },
            {
              element: `#asset-${meta?.id}-download`,
              popover: {
                description: 'You can download all outputs by clicking here.',
                showButtons: ['next'],
                nextBtnText: 'Okay!',
              },
            },
            {
              element: `#asset-${meta?.id}`,
              popover: {
                description: 'You can review your model details by clicking on the row.',
                showButtons: ['next'],
                nextBtnText: 'Thanks!',
                onNextClick: () => {
                  flow.destroy();
                },
              },
            },
          ]);

          flow.drive();
          break;
        case OnboardingFlows.NEW_PRESETS:
          flow.setSteps([
            {
              popover: {
                title: 'Welcome to the New Settings Experience!',
                description:
                  '<p style="padding: 5px;">You are now previewing our new settings that are designed to make your workflow smoother and more efficient.</p><div style="position: relative; padding-bottom: calc(59.8314606741573% + 41px); height: 0; width: 100%;"><iframe src="https://demo.arcade.software/T7GylGfzjjNtREZ0tmm5?embed&show_copy_link=true" title="RapidPipeline" frameborder="0" loading="lazy" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="clipboard-write" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;color-scheme: light;"></iframe></div>',
                showButtons: ['next'],
                nextBtnText: "What's new?",
                popoverClass: 'new-preset custom-theme',
              },
            },
            {
              element: `#${OnboardingFlows.NEW_PRESETS}-0`,
              popover: {
                title: "What's new?",
                description: 'All 3D Processor settings are now organised under 7 categories',
                showButtons: ['next'],
                nextBtnText: 'Next',
                onNextClick: async () => {
                  setFormOptionsOpened(true);
                  await new Promise((resolve) => setTimeout(resolve, 100));
                  flow.moveNext();
                },
              },
            },
            {
              element: `#${OnboardingFlows.NEW_PRESETS}-2`,
              popover: {
                title: "What's new?",
                description:
                  'These settings are designed for easy setup and achieving good results, with additional control for more experienced users.',
                showButtons: ['next'],
                nextBtnText: 'Next',
              },
            },
            {
              element: `#${OnboardingFlows.NEW_PRESETS}-3`,
              popover: {
                title: "What's new?",
                description: 'Offers the most detailed control over all 3D operations.',
                showButtons: ['next'],
                nextBtnText: 'Next',
              },
            },
            {
              popover: {
                title: "What's new?",
                description: 'For the time being, you are limited to running the factory presets.',
                showButtons: ['next'],
                nextBtnText: 'Okay',
              },
            },
            {
              popover: {
                title: "What's new?",
                description: 'You can still explore the settings and share your feedback!',
                showButtons: ['next'],
                nextBtnText: 'Nice!',
              },
            },
            {
              popover: {
                title: "What's new?",
                description: 'Fully customizable preset editor with the new settings will be live soon.',
                showButtons: ['next'],
                nextBtnText: 'Awesome!',
              },
            },
          ]);

          flow.drive();
          break;
        default:
          return;
      }
      markOnboardingComplete(name);
    },
    [availableFlows, growthbook, markOnboardingComplete, setFormOptionsOpened]
  );

  return <OnboardingContext.Provider value={{ runFlow }}>{children}</OnboardingContext.Provider>;
};

export const useOnboarding = () => useContext(OnboardingContext);
