import { ModelDescriptor } from '@/app/components/supportTicket/types';
import { Group, Stack } from '@mantine/core';
import { ModelRow } from '@/app/components/supportTicket/ModelRow';
import CloseIcon from '@/icons/close.svg';

export function ModelsSearchList({
  models,
  onRemoveModel,
}: {
  models: ModelDescriptor[];
  onRemoveModel: (model: ModelDescriptor) => void;
}) {
  return (
    <Stack gap='xs'>
      {models.map((m) => (
        <Group key={m.id} gap='xs' justify='space-between' wrap='nowrap'>
          <ModelRow key={m.id} model={m} />
          <CloseIcon
            style={{ cursor: 'pointer' }}
            onClick={() => onRemoveModel(m)}
          />
        </Group>
      ))}
    </Stack>
  );
}
