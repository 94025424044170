import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '@/app/components/modal/ConfirmationModal';
import { useDisclosure } from '@mantine/hooks';

type ShowArgs = Pick<ConfirmationModalProps, 'title' | 'action' | 'content'>;

type ConfirmationModalContextValue = {
  showConfirmationModal: (args: ShowArgs) => Promise<boolean>;
};

const ConfirmationModalContext = createContext<ConfirmationModalContextValue>({
  showConfirmationModal: () => Promise.resolve(false),
});

export const ConfirmationModalProvider = ({ children }: PropsWithChildren) => {
  const [opened, { open, close }] = useDisclosure();
  const resolveRef = useRef<{
    resolve?: (val: boolean) => void;
    reject?: () => void;
  }>({});

  const [modalProps, setModalProps] =
    useState<Pick<ConfirmationModalProps, 'title' | 'action' | 'content'>>();
  const showModal = (args: ShowArgs) => {
    setModalProps(args);
    open();
    resolveRef.current.resolve?.(false);
    return new Promise<boolean>((resolve, reject) => {
      resolveRef.current.resolve = resolve;
      resolveRef.current.reject = reject;
    });
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => resolveRef.current.resolve?.(false);
  }, []);

  return (
    <ConfirmationModalContext.Provider
      value={{ showConfirmationModal: showModal }}
    >
      {children}
      {modalProps && (
        <ConfirmationModal
          {...modalProps}
          onConfirm={() => {
            resolveRef.current.resolve?.(true);
            close();
          }}
          close={() => {
            resolveRef.current.resolve?.(false);
            close();
          }}
          opened={opened}
        />
      )}
    </ConfirmationModalContext.Provider>
  );
};

export const useConfirmationModal = () => {
  return useContext(ConfirmationModalContext);
};
