import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { GrowthBook, configureCache } from '@growthbook/growthbook';
import { useSession } from 'next-auth/react';

export type GrowthbookFeature = 'show-uvunwrap';

const GrowthbookContext = createContext<GrowthBook | null>(null);

export const GrowthbookProvider = ({ children }: PropsWithChildren) => {
  const [growthbook, setGrowthbook] = useState<GrowthBook | null>(null);
  const { data: session } = useSession();

  useEffect(() => {
    const GROWTHBOOK_API_HOST = process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST;
    const GROWTHBOOK_CLIENT_KEY = process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY;
    (async () => {
      if (!GROWTHBOOK_API_HOST || !GROWTHBOOK_CLIENT_KEY) {
        console.error('Missing env variables for `GROWTHBOOK_API_HOST` or `GROWTHBOOK_CLIENT_KEY`');
        return;
      }

      const gb = new GrowthBook({
        apiHost: GROWTHBOOK_API_HOST,
        clientKey: GROWTHBOOK_CLIENT_KEY,
        attributes: {
          userId: session?.user.id,
          email: session?.user.email,
        },
        /* decryptionKey: process.env.NEXT_PUBLIC_GROWTHBOOK_DECRYPTION_KEY, */
      });

      configureCache({
        // The localStorage key the cache will be stored under
        cacheKey: 'gbFeaturesCache',
        // Consider features stale after this much time (60 seconds default)
        staleTTL: 1000 * 60,
        // Cached features older than this will be ignored (24 hours default)
        maxAge: 1000 * 60 * 60 * 24,
        // Set to `true` to completely disable both in-memory and persistent caching
        disableCache: true,
      });

      await gb.init();
      setGrowthbook(gb);
    })();
  }, [session]);

  return <GrowthbookContext.Provider value={growthbook}>{children}</GrowthbookContext.Provider>;
};

export const useGrowthbookContext = () => useContext(GrowthbookContext);
