import { Card, Group, Progress, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

type DownloadProgressProps = {
  progress: number;
  isDownloadPending: boolean;
};

export function DownloadProgress({
  progress,
  isDownloadPending,
}: DownloadProgressProps) {
  const { width } = useViewportSize();

  if (!isDownloadPending) {
    return null;
  }

  const right = Math.max(width / 2 - 200, 20);

  return (
    <Card
      shadow='md'
      style={{ position: 'absolute', top: 70, right, width: 600, zIndex: 300 }}
    >
      <Text mb='xs' fw={600}>
        Preparing download
      </Text>
      <Group>
        <Progress
          color='teal.7'
          value={progress * 100}
          size='lg'
          transitionDuration={300}
          flex={1}
        />
      </Group>
      <Text mt='xs'>{(progress * 100).toFixed(0)}% completed</Text>
    </Card>
  );
}
