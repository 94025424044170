import { createContext, PropsWithChildren, useContext } from 'react';
import { DownloadProgress } from '@/app/assets/components/table/atoms/DownloadProgress';
import { useZipDownload } from '@/app/components/shared/fileDownload/useZipDownload';

type FileDownloadContextValue = {
  downloadZip: (
    files: { name: string; orgName?: string; url: string }[],
    downloadName?: string,
    isRawModel?: boolean
  ) => void;
};

const FileDownloadContext = createContext<FileDownloadContextValue>({
  downloadZip: () => {},
});

export const FileDownloadProvider = ({ children }: PropsWithChildren) => {
  const { download, progress, isPending } = useZipDownload();

  return (
    <FileDownloadContext.Provider value={{ downloadZip: download }}>
      {children}
      <DownloadProgress progress={progress.progress} isDownloadPending={isPending} />
    </FileDownloadContext.Provider>
  );
};

export const useFileDownload = () => {
  return useContext(FileDownloadContext);
};
