export enum OnboardingFlows {
  UPLOAD = 'upload',
  ASSET_MORE_INFO = 'asset_more_info',
  SIDEBAR = 'sidebar',
  RUN_PRESET = 'run_preset',
  PRESET_MODAL = 'preset_modal',
  EXPAND_ASSET = 'expand_asset',
  NEW_PRESETS = 'new_presets',
}

export type Onboarding = {
  status: 'ready' | 'done';
  name: OnboardingFlows;
};
