import axios, { AxiosProgressEvent } from 'axios';

export async function getBlob(
  url: string,
  updateProgress: (progressEvent: AxiosProgressEvent) => void
) {
  const response = await axios.get(url, {
    responseType: 'blob',
    onDownloadProgress: updateProgress,
  });
  return response.data;
}
