import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { RpdxVersions, getAppVersion, getRPDXVersion, getRapidPBRVersion } from '../actions/versions';

export const versionsQueryKey = ['versions'];

export function useVersionsQuery(
  options?: Omit<
    UseQueryOptions<{ pbrVersion: string; rpdxVersions: RpdxVersions; appVersion: string }>,
    'queryKey' | 'queryFn'
  >
) {
  return useQuery<{ pbrVersion: string; rpdxVersions: RpdxVersions; appVersion: string }>({
    ...options,
    queryKey: versionsQueryKey,
    queryFn: async () => {
      const rpdxVersions = await getRPDXVersion();
      const pbrVersion = await getRapidPBRVersion();
      const appVersion = await getAppVersion();

      return { pbrVersion, rpdxVersions, appVersion };
    },
    throwOnError: false,
  });
}
