import { useState } from 'react';
import { Combobox, Loader, TextInput, useCombobox } from '@mantine/core';
import { useSearchForModelsQuery } from '@/app/components/supportTicket/query/useSearchForModelsQuery';
import { ModelDescriptor } from '@/app/components/supportTicket/types';
import { ModelRow } from '@/app/components/supportTicket/ModelRow';

interface ModelsSearchInputProps {
  onModelSelected: (model: ModelDescriptor) => void;
}

export function ModelsSearchInput({ onModelSelected }: ModelsSearchInputProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const { data: models, isPending } = useSearchForModelsQuery(searchTerm);
  const options =
    models?.map((item) => (
      <Combobox.Option value={item.id.toString()} key={item.id}>
        <ModelRow model={item} />
      </Combobox.Option>
    )) ?? [];

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearchTerm('');
    },
  });

  return (
    <Combobox
      store={combobox}
      width={250}
      position='bottom-start'
      withArrow
      onOptionSubmit={(val) => {
        const model = models?.find((m) => m.id === parseInt(val));
        combobox.closeDropdown();
        if (model) {
          onModelSelected(model);
        }
      }}
    >
      <Combobox.Target withAriaAttributes={false}>
        <TextInput
          value={searchTerm}
          label='Search for a model to include it in your ticket'
          rightSection={isPending ? <Loader /> : null}
          onChange={(event) => {
            setSearchTerm(event.currentTarget.value);
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
          }}
          onClick={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
        />
      </Combobox.Target>
      <Combobox.Dropdown w={540}>
        <Combobox.Options mah={300} style={{ overflowY: 'auto' }}>
          {options.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
