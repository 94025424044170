'use client';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useSearchParams } from 'next/navigation';
import { RawModel } from '@/types/rawModel';
import { getRawModels } from '../actions/rawModel';
import { ONLY_OUTPUTS_KEY, PAGE_QUERY_KEY, SEARCH_QUERY_KEY } from '../utils/constants';
import { getSearchParam } from '@/utils/getSearchParam';
import { Page } from '@/types/pagination';
import { useSelectedAssetContext } from '../contexts/SelectedAssetContext';
import { useMemo } from 'react';
import { getOutputModels } from '../actions/outputModel';

export const MAIN_ASSET_QUERY_KEY = 'assets';

export const useAssetsQueryKey = (search?: string | null, page?: string | null, onlyOutputs?: string | null) => {
  return useMemo(() => [MAIN_ASSET_QUERY_KEY, search, page, onlyOutputs], [page, search, onlyOutputs]);
};

export const useAssetsQuery = (
  options?: Omit<UseQueryOptions<Page<RawModel>>, 'queryKey' | 'queryFn'>,
  omitParams?: boolean
) => {
  const { page, query, onlyOutputs } = useAssetsParams();

  return useQuery({
    ...options,
    staleTime: Infinity,
    queryKey: useAssetsQueryKey(query, page, onlyOutputs),
    queryFn: async () => {
      if (omitParams) {
        return onlyOutputs === 'true' ? getOutputModels() : getRawModels();
      }
      const rawModels = await (onlyOutputs === 'true'
        ? getOutputModels(query ?? undefined, page ? +page : undefined)
        : getRawModels(query ?? undefined, page ? +page : undefined));
      return rawModels;
    },
    structuralSharing: false,
    placeholderData: (prev) => prev,
  });
};

export const useSelectedAsset = () => {
  const { selectedAsset } = useSelectedAssetContext();
  const { data } = useAssetsQuery();

  const rawModel = data?.data.find((asset) => asset.id === selectedAsset?.basicInfo.id);
  if (rawModel) {
    return rawModel;
  }
  return data?.data
    .flatMap((rawModel) => rawModel.rapidModels)
    .find((asset) => asset.id === selectedAsset?.basicInfo.id);
};

export const useAssetsParams = () => {
  const searchParams = useSearchParams();

  const query = getSearchParam(searchParams.get(SEARCH_QUERY_KEY));
  const page = getSearchParam(searchParams.get(PAGE_QUERY_KEY));
  const onlyOutputs = getSearchParam(searchParams.get(ONLY_OUTPUTS_KEY));

  return {
    query,
    page,
    onlyOutputs,
  };
};

export const useRawModel = (outputModelId: number) => {
  const { data: rawModelsReponse } = useAssetsQuery();

  const rawModel = rawModelsReponse?.data.find((asset) =>
    asset.rapidModels.find((rapidModel) => rapidModel.id === outputModelId)
  );
  if (rawModel) {
    return rawModel;
  }
  return null;
};

export const useAllAssets = () => {
  const queryClient = useQueryClient();
  const allAssets = queryClient
    .getQueryCache()
    .findAll({ predicate: (query) => query.queryKey.includes(MAIN_ASSET_QUERY_KEY) })
    .filter((query) => query.state.status === 'success')
    .flatMap((query) => (query.state.data as { data: RawModel }).data);
  return allAssets;
};
