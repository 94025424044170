import Link from 'next/link';
import ExternalLinkIcon from '@/icons/external-link.svg';
import { PropsWithChildren } from 'react';

export function ExternalLink({ href, target, children }: PropsWithChildren & { href: string; target?: string }) {
  return (
    <Link style={{ display: 'inline-flex', alignItems: 'center' }} target={target} href={href}>
      {children}
      <ExternalLinkIcon style={{ height: '1em', color: 'transparent' }} />
    </Link>
  );
}
