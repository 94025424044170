import { notifications } from '@mantine/notifications';

export const showSuccessNotification = ({
  title,
  message,
}: {
  title?: string;
  message: string;
}) => {
  notifications.show({
    title,
    message,
    color: 'green.6',
    autoClose: 8000,
    style: { padding: 26 },
  });
};
