import { useSession } from 'next-auth/react';
import { Onboarding, OnboardingFlows } from './constants';
import { useUserOnboardingQuery } from './useUserOnboardingQuery';
import { useMemo } from 'react';

export const getNotCompletedFlows = (userOnboarding: Onboarding[]) => {
  if (!('filter' in userOnboarding)) {
    return [];
  }
  const completed = userOnboarding.filter((flow) => flow.status === 'done').map((flow) => flow.name);
  return Object.values(OnboardingFlows).filter((flow) => !completed.includes(flow));
};

export type AvailableFlow = {
  availableFlows: string[];
  isFetched: boolean;
};

export const useAvailableFlows = (): AvailableFlow => {
  const { status } = useSession();
  const {
    data: userOnboarding,
    isLoading: isLoadingUserOnboarding,
    isFetched,
  } = useUserOnboardingQuery(status === 'authenticated');

  const strigifiedUserOnboarding = JSON.stringify(userOnboarding);
  const availableFlows = useMemo(() => {
    if (isLoadingUserOnboarding) {
      return [];
    }

    const notCompletedFlows = getNotCompletedFlows(userOnboarding ?? []);
    return notCompletedFlows;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we need to run this effect only when the userOnboarding changes
  }, [isLoadingUserOnboarding, userOnboarding, strigifiedUserOnboarding]);

  return {
    availableFlows,
    isFetched,
  };
};
