import { Box, Divider, Modal, ModalProps, ScrollArea, Text } from '@mantine/core';
import { useResizeObserver, useViewportSize } from '@mantine/hooks';
import { ForwardedRef, PropsWithChildren, ReactNode } from 'react';

interface ScrollableModalProps extends PropsWithChildren, ModalProps {
  opened: boolean;
  title: string;
  nonScrollableTop?: ReactNode;
  nonScrollableBottom?: ReactNode;
  viewportRef?: ForwardedRef<HTMLDivElement>;
}
export function ScrollableModal({
  opened,
  title,
  nonScrollableTop,
  viewportRef,
  children,
  nonScrollableBottom,
  ...props
}: ScrollableModalProps) {
  return (
    <Modal
      opened={opened}
      classNames={{
        root: 'h-full',
        content: 'flex flex-col',
        body: 'flex flex-col h-full  pb-0',
        header: 'py-0',
      }}
      title={
        <Text size='lg' fw={600}>
          {title}
        </Text>
      }
      {...props}
    >
      <ModalContent
        nonScrollableBottom={nonScrollableBottom}
        nonScrollableTop={nonScrollableTop}
        viewportRef={viewportRef}
      >
        {children}
      </ModalContent>
    </Modal>
  );
}

//this had to be extracted to separate component so the refs work as expected
function ModalContent({ children, nonScrollableBottom, nonScrollableTop, viewportRef }: Partial<ScrollableModalProps>) {
  const { height: viewportHeight } = useViewportSize();
  const [bottomRef, { height: bottomHeight }] = useResizeObserver();
  const [topRef, { height: topHeight }] = useResizeObserver();
  return (
    <>
      <Box ref={topRef}>
        <Divider mx={-24} />
        {nonScrollableTop}
      </Box>
      <ScrollArea.Autosize
        my='sm'
        type='scroll'
        mah={viewportHeight - bottomHeight - topHeight - viewportHeight / 4} // *viewportHeight / 4* is a magic number, seems to work fine on common resolutions
        offsetScrollbars={true}
        viewportRef={viewportRef}
        scrollbars='y'
      >
        {children}
      </ScrollArea.Autosize>
      <Box ref={bottomRef}>
        <Divider mx={-24} />
        {nonScrollableBottom}
      </Box>
    </>
  );
}
