import { Stack, Textarea, TextInput } from '@mantine/core';
import { ModelsSearchInput } from '@/app/components/supportTicket/ModelsSearchInput';
import { ModelsSearchList } from '@/app/components/supportTicket/ModelsSearchList';
import { UseFormReturnType } from '@mantine/form';
import { SupportRequestFormValues } from '@/app/components/supportTicket/SupportTicketModal';

export function SupportTicketForm({
  form,
}: {
  form: UseFormReturnType<SupportRequestFormValues>;
}) {
  return (
    <Stack gap='xs'>
      <TextInput
        label='Subject'
        required
        withAsterisk
        {...form.getInputProps('subject')}
      />
      <ModelsSearchInput
        onModelSelected={(m) => {
          const exists = form.values.models.find((v) => v.id === m.id);
          if (exists) {
            return;
          }
          form.insertListItem('models', m);
        }}
      />
      <ModelsSearchList
        models={form.values.models}
        onRemoveModel={(m) =>
          form.setFieldValue('models', (prevValue) =>
            prevValue.filter((v) => v.id !== m.id)
          )
        }
      />
      <Textarea
        required
        {...form.getInputProps('message')}
        label='Message'
        autosize
        minRows={3}
      />
    </Stack>
  );
}
