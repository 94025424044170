import { Badge, Group, Text } from '@mantine/core';
import { ModelDescriptor } from '@/app/components/supportTicket/types';

export function ModelRow({ model }: { model: ModelDescriptor }) {
  return (
    <Group justify='space-between' wrap='nowrap' w='100%'>
      <Text>#{model.id}</Text>
      <Text>{model.name}</Text>
      <Badge radius='sm' p='xs'>
        <Text>{model.type === 'raw' ? 'Source Model' : 'Optimized Model'}</Text>
      </Badge>
    </Group>
  );
}
