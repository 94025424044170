'use client';
import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { AssetRow } from '@/app/assets/components/table/hooks/types';

type SelectedAssetIdValue = {
  selectedAsset?: AssetRow;
  setSelectedAsset: (assetRow: AssetRow | undefined) => void;
};

const SelectedAssetIdContext = createContext<SelectedAssetIdValue>({
  setSelectedAsset: () => {},
});

export const SelectedAssetProvider = ({ children }: PropsWithChildren) => {
  const [selectedAsset, setSelectedAsset] = useState<AssetRow | undefined>();

  return (
    <SelectedAssetIdContext.Provider
      value={{
        selectedAsset: selectedAsset,
        setSelectedAsset: (assetRow?: AssetRow) => {
          setSelectedAsset(assetRow);
        },
      }}
    >
      {children}
    </SelectedAssetIdContext.Provider>
  );
};

export const useSelectedAssetContext = () => {
  return useContext(SelectedAssetIdContext);
};
