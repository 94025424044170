import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Onboarding, OnboardingFlows } from './constants';
import { markOnboardingComplete } from './onboardingActions';
import { USER_ONBOARDING_QUERY_KEY } from './useUserOnboardingQuery';

export const useMarkOnboarding = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['markOnboarding'],
    onMutate: async (name: OnboardingFlows) => {
      queryClient.setQueryData<Onboarding[]>([USER_ONBOARDING_QUERY_KEY], (data) => {
        if (!data) {
          return [];
        }
        const newData = data.map((flow) => {
          if (flow.name === name) {
            flow.status = 'done';
          }
          return flow;
        });
        return newData;
      });
    },
    mutationFn: async (name: OnboardingFlows) => {
      return markOnboardingComplete(name);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USER_ONBOARDING_QUERY_KEY] });
    },
  });
};
